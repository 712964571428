import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">


<path d="M1145 2990 c-11 -5 -58 -20 -105 -35 -119 -37 -293 -122 -402 -196
-125 -85 -315 -275 -397 -399 -103 -155 -157 -274 -225 -497 -23 -76 -23 -650
0 -726 100 -329 200 -504 409 -712 201 -202 398 -318 676 -400 81 -24 94 -25
398 -25 l314 0 101 29 c266 77 489 213 686 417 168 174 288 381 364 625 l36
115 0 316 0 315 -44 134 c-83 256 -193 436 -381 624 -165 166 -313 265 -515
343 -219 85 -200 82 -567 81 -180 0 -337 -4 -348 -9z m645 -40 c138 -28 233
-60 355 -120 422 -206 706 -572 806 -1040 19 -88 23 -136 23 -290 0 -154 -4
-202 -23 -290 -30 -143 -62 -235 -125 -365 -72 -147 -160 -269 -281 -390 -213
-212 -454 -342 -755 -406 -151 -33 -429 -33 -580 0 -140 30 -235 62 -355 121
-699 341 -1012 1146 -730 1875 190 492 664 858 1195 924 118 15 349 6 470 -19z"/>
<path d="M326 2167 c-32 -32 -30 21 -27 -732 2 -539 4 -582 20 -605 10 -14 29
-27 42 -28 39 -6 422 49 446 64 43 27 46 64 38 649 -4 336 -10 560 -17 577 -5
15 -17 30 -26 34 -14 5 -151 26 -404 60 -41 6 -49 3 -72 -19z m129 -42 c17
-19 37 -24 134 -35 104 -11 116 -11 127 5 16 21 80 11 94 -16 6 -11 10 -237
10 -586 0 -459 -3 -573 -13 -587 -13 -18 -71 -28 -394 -70 -90 -11 -83 -63
-83 659 0 620 0 634 20 648 27 19 80 10 105 -18z"/>
<path d="M565 898 c-69 -10 -90 -16 -83 -24 8 -7 191 17 203 27 11 11 -39 10
-120 -3z"/>
<path d="M1139 2134 c-18 -13 -42 -24 -53 -24 -11 0 -36 -11 -56 -25 -35 -24
-38 -25 -74 -10 -38 16 -69 9 -79 -17 -3 -7 -6 3 -8 22 -5 89 -17 -89 -17
-260 0 -190 6 -245 23 -245 20 0 165 -59 165 -67 0 -4 -4 -8 -9 -8 -5 0 -19
-16 -32 -36 -19 -32 -23 -54 -27 -164 -2 -70 -1 -162 3 -204 l7 -76 80 -40
c44 -22 92 -40 107 -40 15 0 58 15 96 34 67 32 102 62 138 115 l16 23 7 -36
c8 -45 40 -69 96 -72 22 -1 44 -8 51 -17 15 -20 79 -47 109 -47 30 1 139 78
155 110 15 29 17 182 3 250 -4 25 -7 46 -5 48 6 6 55 -22 53 -30 -2 -5 -8 -8
-13 -6 -12 4 -23 -279 -12 -304 4 -10 13 -15 20 -12 8 3 18 -6 24 -19 11 -24
68 -47 116 -47 14 0 54 19 89 42 53 36 63 48 66 76 4 43 28 42 76 -3 22 -20
52 -37 74 -41 20 -4 63 -20 94 -35 32 -16 67 -29 79 -29 28 0 137 68 156 97
24 36 14 292 -14 353 -26 59 -74 107 -123 121 l-39 12 97 39 c53 21 103 46
109 55 7 9 13 36 13 59 0 43 -13 64 -51 84 -16 9 -19 22 -19 74 0 60 2 64 25
70 42 11 61 99 30 142 -23 34 -148 73 -234 73 -80 0 -115 -10 -147 -40 -21
-19 -21 -19 -93 22 -78 45 -114 51 -173 26 -18 -8 -56 -17 -83 -21 -27 -5 -68
-18 -90 -29 -22 -12 -49 -22 -60 -22 -13 0 -21 -8 -23 -23 -3 -23 -4 -23 -38
7 -46 41 -131 65 -199 56 -73 -9 -140 -52 -149 -96 -8 -43 -24 -27 -28 31 -3
51 -17 62 -130 104 l-66 25 -33 -25z m148 -46 l66 -31 -7 -135 c-3 -74 -6
-168 -6 -209 l0 -74 -35 -15 c-35 -14 -36 -14 -95 28 l-60 42 0 58 c0 37 -4
58 -12 58 -8 0 -9 -30 -4 -110 6 -101 5 -111 -13 -129 -11 -11 -35 -23 -55
-26 -29 -6 -46 0 -105 34 l-71 42 0 57 c0 175 14 374 26 379 7 3 45 -10 85
-28 59 -28 72 -31 79 -18 5 8 27 38 51 67 48 61 48 61 156 10z m900 0 c40 -21
63 -39 63 -50 0 -9 -18 -58 -40 -108 -22 -50 -40 -97 -40 -105 0 -7 19 -46 42
-86 39 -68 40 -72 23 -85 -27 -20 -80 -27 -103 -15 -31 16 -37 14 -54 -22 -12
-24 -27 -37 -57 -47 -38 -12 -45 -11 -90 9 -27 13 -61 33 -76 45 l-27 22 51
103 c28 57 51 107 51 112 0 5 -20 41 -45 81 -25 40 -45 78 -45 84 0 6 18 17
40 24 36 12 45 11 78 -6 20 -10 40 -20 44 -22 3 -2 9 10 13 26 9 43 43 69 90
71 10 1 47 -14 82 -31z m403 -17 l75 -29 0 -53 c0 -47 -3 -55 -25 -66 -23 -11
-25 -20 -30 -88 l-5 -77 33 -20 c28 -17 32 -25 32 -64 l0 -45 -62 -28 c-35
-16 -86 -37 -114 -47 l-51 -17 -69 34 c-59 29 -69 38 -72 63 -6 46 5 71 39 87
31 15 31 16 36 101 l5 87 -39 18 c-33 16 -38 23 -41 56 -2 21 2 44 8 52 12 14
155 61 195 63 6 1 44 -12 85 -27z m-933 -12 c109 -40 122 -69 123 -276 0 -106
-3 -135 -16 -147 -17 -18 -79 -22 -89 -6 -10 17 -25 11 -25 -9 0 -28 -47 -61
-87 -61 -49 0 -139 62 -147 102 -10 52 -7 153 9 234 17 87 46 134 105 165 44
24 56 24 127 -2z m176 -121 c32 -50 35 -116 5 -150 -10 -12 -18 -26 -18 -31 0
-6 -3 -8 -6 -4 -3 3 -8 38 -10 79 -2 40 -6 90 -9 111 -7 48 4 47 38 -5z m455
-18 c40 -54 20 -207 -27 -198 -8 2 -28 25 -44 51 l-29 48 27 65 c15 35 33 64
39 64 7 0 22 -13 34 -30z m-1071 -306 c49 -28 84 -27 126 6 24 19 24 19 46
-10 12 -17 29 -30 38 -30 9 0 40 -12 69 -27 49 -25 56 -26 95 -14 23 7 52 23
64 37 13 14 29 22 37 19 8 -3 36 1 62 10 42 15 50 15 64 2 10 -8 44 -26 77
-40 58 -25 59 -26 34 -35 -15 -6 -35 -12 -45 -14 -11 -2 -30 -13 -44 -25 -14
-13 -30 -23 -37 -23 -7 0 -13 -5 -13 -10 0 -6 -18 3 -40 21 -53 42 -113 49
-195 20 -67 -23 -115 -68 -115 -106 0 -34 -11 -31 -39 11 -40 58 -209 132
-278 121 l-34 -6 33 22 c26 18 34 31 36 60 2 20 6 37 9 37 4 0 26 -12 50 -26z
m924 -5 c14 -7 30 -7 55 1 29 10 38 9 47 -2 11 -13 82 -47 162 -78 l30 -12
-56 -17 c-31 -10 -67 -25 -79 -34 -21 -15 -24 -14 -40 5 -12 15 -49 30 -126
49 -101 25 -107 28 -81 38 15 6 32 22 39 36 12 28 20 30 49 14z m-23 -108
c135 -35 132 -33 132 -90 0 -49 0 -49 -45 -68 l-44 -18 -3 -149 -3 -149 -63
-40 c-62 -39 -65 -39 -105 -27 -23 6 -48 17 -54 22 -10 8 -13 58 -13 188 l0
177 -40 16 c-37 14 -40 18 -40 54 0 48 7 57 50 72 19 6 46 18 60 26 29 18 55
15 168 -14z m-937 -5 c62 -18 142 -57 157 -76 40 -48 55 -96 55 -170 0 -119
-49 -208 -144 -255 -66 -34 -102 -32 -182 11 l-67 35 2 193 c3 213 9 234 71
261 41 18 54 18 108 1z m527 -15 c46 -23 67 -46 87 -94 21 -51 39 -230 28
-292 -8 -46 -13 -53 -63 -87 -68 -45 -114 -50 -153 -14 -17 15 -24 31 -21 40
7 18 -21 23 -31 6 -9 -15 -58 -12 -80 4 -17 12 -20 30 -23 125 -8 212 12 269
108 308 68 28 100 28 148 4z m809 8 c46 -17 92 -69 109 -124 18 -57 30 -236
20 -286 -9 -43 -99 -109 -150 -109 -40 0 -86 33 -86 62 0 16 -4 18 -17 12 -26
-12 -91 -10 -104 3 -16 16 -16 289 -1 334 16 47 47 75 108 99 57 22 82 24 121
9z m-288 -264 c1 -58 -3 -105 -8 -105 -17 0 -28 25 -33 71 -4 38 -2 50 14 63
16 14 17 20 8 32 -10 11 -10 17 0 29 7 8 14 15 15 15 2 0 4 -47 4 -105z"/>
<path d="M1160 2065 c-12 -20 -21 -38 -19 -39 2 -2 22 -11 43 -20 22 -9 37
-19 34 -22 -4 -3 -25 4 -48 16 -40 21 -42 21 -56 3 -19 -26 -19 -26 31 -62
l44 -32 30 33 c17 18 28 39 26 46 -3 7 -1 11 4 7 9 -5 51 40 51 54 0 4 -23 16
-52 27 -29 10 -55 20 -59 22 -4 2 -17 -13 -29 -33z m82 -36 c11 -6 18 -13 15
-16 -5 -5 -87 34 -87 42 0 5 42 -10 72 -26z m-44 -76 c-12 -15 -32 -4 -49 29
-9 17 -8 17 25 1 28 -13 33 -19 24 -30z"/>
<path d="M930 2012 c0 -11 17 -26 46 -40 26 -13 44 -26 41 -29 -4 -3 -22 4
-42 16 -43 25 -45 25 -45 6 0 -8 5 -15 11 -15 16 0 81 -41 75 -47 -3 -3 -20 4
-39 16 -43 26 -44 26 -44 -4 0 -19 10 -31 44 -51 25 -14 42 -28 39 -31 -3 -3
-17 4 -33 14 -48 33 -53 35 -53 19 0 -9 5 -16 11 -16 5 0 27 -11 47 -25 20
-14 31 -25 24 -25 -7 0 -10 -4 -7 -10 10 -16 -8 -11 -35 10 -21 17 -22 19 -5
14 20 -6 20 -5 1 10 -11 9 -23 16 -26 16 -3 0 -6 -47 -6 -105 0 -57 2 -102 4
-100 3 2 18 -7 34 -20 42 -35 55 -32 53 11 -3 39 -15 60 -15 26 0 -19 -3 -20
-25 -10 -18 8 -22 14 -15 23 7 9 6 14 -4 18 -15 6 -16 23 -3 45 5 7 2 14 -8
18 -14 6 -14 8 2 21 10 8 13 12 7 9 -7 -3 -14 1 -17 9 -5 12 -2 12 18 3 13 -6
22 -15 19 -19 -3 -5 1 -6 9 -3 8 3 18 1 22 -5 3 -7 0 -8 -9 -5 -10 4 -13 0
-11 -13 2 -10 4 -18 5 -18 1 0 3 -8 5 -18 2 -11 -1 -15 -8 -11 -9 5 -9 4 0 -6
22 -24 31 0 31 83 l0 83 -40 23 c-22 12 -37 25 -34 28 2 3 18 -4 34 -16 36
-26 41 -26 40 -3 -1 9 2 33 5 52 7 35 6 36 -48 63 -49 24 -55 25 -55 9z"/>
<path d="M1272 1958 c-23 -30 -51 -63 -62 -73 -18 -16 -20 -16 -52 6 -18 12
-45 34 -60 47 -15 14 -30 23 -32 21 -4 -4 -9 -334 -6 -381 0 -4 10 -4 22 1 20
7 21 13 21 134 0 70 3 130 8 132 4 3 5 13 2 22 -4 11 -2 14 8 11 8 -3 13 -10
12 -16 -2 -6 11 -21 28 -33 l31 -21 33 32 c18 18 32 37 29 44 -2 6 5 22 15 35
l18 24 5 -36 c2 -23 0 -37 -7 -37 -8 0 -12 -37 -14 -111 -2 -102 -1 -110 16
-107 16 3 19 20 26 163 10 214 11 210 -41 143z"/>
<path d="M1197 1778 c-27 -15 -20 -60 14 -86 l29 -24 0 35 c0 26 -6 37 -25 47
-14 7 -23 15 -21 17 2 3 14 0 25 -7 24 -12 28 -5 8 15 -9 9 -19 10 -30 3z"/>
<path d="M2084 2083 c2 -5 25 -19 50 -33 34 -18 48 -22 57 -13 9 9 1 17 -29
32 -40 20 -88 29 -78 14z"/>
<path d="M2042 2032 c-8 -25 -6 -31 23 -58 18 -16 30 -36 28 -44 -3 -8 4 -1
15 15 28 42 37 75 20 75 -7 0 -24 9 -38 20 -33 26 -36 25 -48 -8z"/>
<path d="M1890 2022 c0 -14 86 -51 100 -42 9 6 1 14 -28 29 -43 22 -72 27 -72
13z"/>
<path d="M2181 2001 c-15 -15 -41 -73 -57 -128 -7 -27 -34 -11 -61 37 -21 37
-34 50 -50 50 -13 0 -23 -4 -23 -8 0 -4 20 -37 45 -73 53 -74 53 -70 -5 -197
-21 -48 -37 -88 -36 -89 2 -2 13 0 25 5 15 5 33 31 51 73 16 35 35 65 44 67 9
2 24 -13 38 -37 23 -40 41 -51 53 -31 3 5 -10 38 -29 73 -20 35 -36 71 -36 80
0 9 17 57 39 107 38 89 39 108 2 71z m-59 -188 c3 -16 9 -36 13 -45 6 -14 2
-18 -17 -18 -21 0 -23 3 -20 45 4 50 17 60 24 18z"/>
<path d="M1925 1940 c14 -22 25 -43 25 -48 0 -8 43 -32 57 -32 3 0 -1 8 -10
18 -10 10 -14 22 -10 25 3 4 1 7 -5 7 -6 0 -13 8 -16 18 -3 9 -19 25 -36 34
l-29 17 24 -39z"/>
<path d="M1950 1823 c-1 -10 -7 -29 -15 -43 -13 -24 -12 -26 28 -47 23 -12 36
-22 29 -23 -7 0 -10 -5 -6 -11 4 -8 -1 -9 -17 -5 -21 6 -22 5 -6 -4 14 -9 16
-15 7 -26 -10 -11 -17 -11 -34 -2 -11 6 -24 15 -27 20 -3 5 -11 3 -18 -5 -9
-12 -5 -21 18 -46 26 -28 60 -44 46 -22 -3 6 1 13 8 18 14 8 54 102 49 116 -1
4 2 7 6 7 5 0 14 11 20 25 11 23 9 26 -28 45 -48 25 -60 25 -60 3z m47 -40
c11 -4 14 -3 9 5 -5 8 -1 10 10 5 11 -4 13 -8 6 -11 -7 -2 -9 -9 -5 -15 4 -6
0 -8 -10 -4 -12 4 -15 1 -12 -14 3 -17 1 -18 -18 -8 -28 15 -37 24 -16 17 9
-4 16 -1 16 7 0 7 -6 11 -12 9 -6 -3 -12 2 -12 11 0 9 6 14 14 10 7 -3 20 -8
30 -12z"/>
<path d="M1901 1722 c-8 -16 -4 -22 25 -35 37 -18 58 -23 35 -8 -8 5 -16 17
-17 26 -2 12 3 14 19 10 19 -5 19 -4 -3 9 -33 20 -47 20 -59 -2z"/>
<path d="M2505 2060 c5 -9 2 -10 -13 -6 -11 4 -33 4 -48 0 l-27 -7 24 -14 24
-14 -25 6 c-60 16 -70 17 -70 9 0 -5 21 -17 48 -26 44 -15 51 -15 117 2 39 10
72 19 75 20 5 1 -44 21 -89 35 -17 5 -22 4 -16 -5z"/>
<path d="M2340 1990 c0 -21 15 -35 61 -54 34 -14 35 -14 37 8 1 18 -8 26 -48
43 -47 20 -50 20 -50 3z"/>
<path d="M2535 1981 c-52 -15 -60 -21 -63 -44 -3 -22 0 -26 15 -20 10 3 23 10
30 15 8 7 11 -28 12 -132 l2 -141 -31 -10 c-24 -9 -30 -17 -30 -40 0 -16 3
-29 8 -29 4 0 42 14 84 32 51 22 79 40 82 53 7 26 2 29 -24 15 -39 -21 -53 -6
-46 53 3 28 6 84 6 123 0 70 1 72 30 84 21 9 30 19 30 36 0 29 -17 30 -105 5z
m75 -16 c0 -3 -11 -10 -25 -15 -14 -5 -25 -17 -25 -27 0 -15 -2 -16 -10 -3
-15 22 -12 30 13 40 25 10 47 12 47 5z m-30 -315 c0 -9 -31 -30 -44 -30 -4 0
-1 9 6 20 13 21 38 27 38 10z"/>
<path d="M2411 1833 c2 -60 12 -85 32 -78 17 7 46 -15 29 -22 -6 -2 -9 -10 -5
-16 19 -30 10 -36 -26 -17 -36 18 -81 18 -81 -1 0 -4 19 -17 43 -27 36 -16 46
-17 68 -6 24 12 25 17 27 103 1 75 -1 91 -13 91 -8 0 -15 5 -15 11 0 6 -7 9
-15 5 -8 -3 -15 -1 -15 4 0 6 -7 10 -15 10 -12 0 -15 -12 -14 -57z m33 16 c-4
-8 1 -9 17 -5 l24 7 -25 -16 -25 -16 24 7 c16 4 21 3 17 -5 -4 -6 -2 -11 5
-11 7 0 10 -2 7 -5 -2 -3 -19 0 -37 6 -24 9 -29 14 -19 20 10 7 10 9 -2 9 -13
0 -13 1 0 10 20 13 23 12 14 -1z m16 -59 c8 -5 11 -10 5 -10 -5 0 -3 -5 5 -10
12 -8 9 -10 -10 -8 -26 3 -42 17 -34 30 7 10 16 10 34 -2z"/>
<path d="M2340 1652 c0 -26 11 -37 58 -61 l42 -21 0 24 c0 20 -10 30 -50 50
-44 22 -50 23 -50 8z"/>
<path d="M1551 2041 c-11 -7 -9 -11 9 -21 30 -16 19 -24 -14 -9 -18 9 -30 9
-37 2 -7 -7 1 -17 26 -32 l37 -21 26 28 c15 16 34 31 42 34 8 3 12 9 8 12 -11
12 -82 18 -97 7z"/>
<path d="M1616 1963 c-14 -21 -30 -58 -35 -83 -13 -54 -14 -300 -3 -300 22 0
42 39 42 83 0 44 2 48 37 62 50 21 63 14 63 -35 0 -30 4 -40 16 -40 14 0 16
17 13 148 -5 174 -15 202 -74 202 -29 0 -39 -6 -59 -37z m94 -9 c15 -38 13
-41 -20 -36 -17 2 -30 10 -30 18 0 8 -5 14 -11 14 -8 0 -8 5 1 15 19 23 49 18
60 -11z m0 -63 c5 -11 10 -35 10 -54 0 -33 -3 -35 -44 -46 -25 -6 -47 -11 -50
-11 -13 0 -5 87 10 108 19 27 60 29 74 3z m20 -130 c0 -14 -6 -17 -25 -13 -27
5 -33 17 -12 25 25 11 37 7 37 -12z"/>
<path d="M1485 1979 c-4 -6 -5 -11 -4 -12 2 -1 19 -12 37 -25 29 -21 52 -22
52 -4 0 14 -79 52 -85 41z"/>
<path d="M1470 1941 c0 -8 64 -51 76 -51 14 0 -1 18 -31 38 -38 24 -45 26 -45
13z"/>
<path d="M1460 1912 c0 -5 16 -16 36 -26 20 -9 34 -20 31 -23 -4 -3 -18 2 -31
11 -29 18 -40 21 -31 6 3 -5 2 -10 -2 -10 -5 0 -10 -13 -11 -30 -1 -16 3 -30
8 -30 6 0 8 5 4 11 -4 7 1 7 16 -1 12 -6 24 -9 27 -7 3 3 -8 12 -24 21 -15 8
-22 15 -15 16 7 0 10 5 7 10 -9 15 7 12 29 -5 22 -17 40 -20 32 -6 -4 5 -1 11
5 13 6 2 -7 16 -30 31 -43 29 -51 32 -51 19z"/>
<path d="M1505 1830 c17 -11 24 -20 17 -20 -7 0 -10 -4 -7 -10 10 -16 21 -12
22 9 1 11 -9 23 -23 30 -38 17 -42 13 -9 -9z"/>
<path d="M1462 1801 c-10 -6 -5 -14 22 -30 35 -22 47 -41 25 -41 -8 0 -8 -5 1
-15 7 -8 9 -15 4 -15 -5 0 -2 -5 6 -10 9 -6 10 -10 3 -10 -6 0 -15 5 -18 10
-3 6 -11 10 -17 10 -7 0 -7 -4 1 -13 6 -8 7 -18 3 -22 -4 -5 0 -6 9 -3 9 4 16
1 16 -6 0 -6 -8 -12 -18 -12 -10 0 -15 4 -13 8 3 4 -1 5 -9 2 -8 -3 -14 1 -14
10 0 8 6 12 14 9 21 -8 15 5 -7 17 -19 10 -20 9 -17 -14 3 -23 58 -76 78 -76
4 0 6 40 5 90 -1 84 -2 90 -26 102 -14 7 -27 14 -30 15 -3 1 -11 -1 -18 -6z"/>
<path d="M1455 1730 c3 -5 1 -10 -6 -10 -26 0 2 -13 32 -15 l31 -2 -23 18
c-26 20 -44 25 -34 9z"/>
<path d="M1951 1481 c-43 -23 -28 -34 48 -36 75 -3 78 -4 61 -15 -13 -8 -13
-10 2 -10 9 0 19 4 22 9 3 5 15 11 26 14 11 3 18 1 14 -4 -3 -5 -12 -9 -20 -9
-8 0 -14 -4 -14 -10 0 -14 8 -13 57 8 47 19 58 37 18 30 -14 -3 -25 -2 -25 2
0 3 -30 12 -67 20 -38 7 -72 14 -78 16 -5 2 -25 -5 -44 -15z"/>
<path d="M1870 1420 c0 -10 9 -24 19 -30 25 -13 34 -13 26 0 -3 6 1 10 10 10
11 0 15 -5 12 -14 -3 -7 -2 -17 3 -22 5 -5 12 -89 16 -187 6 -159 9 -179 25
-188 11 -5 19 -6 19 -2 0 4 1 92 1 195 0 160 2 187 16 192 10 4 14 2 10 -8 -4
-9 4 -16 21 -19 15 -3 35 -9 45 -13 16 -6 18 -2 15 21 -3 26 -8 29 -113 56
-132 34 -125 34 -125 9z"/>
<path d="M2165 1405 c-27 -12 -37 -28 -28 -47 6 -12 83 33 83 48 0 17 -15 17
-55 -1z"/>
<path d="M2069 1303 c-1 -5 -1 -12 0 -16 1 -5 -2 -6 -7 -3 -4 2 -14 1 -22 -4
-12 -8 -13 -13 -2 -30 7 -11 8 -20 3 -20 -5 0 -7 -30 -5 -67 2 -38 4 -80 4
-96 l0 -28 40 27 c25 17 40 34 40 47 0 21 0 21 -20 -1 -12 -12 -27 -22 -35
-22 -22 0 -6 29 25 45 14 7 27 21 27 29 0 18 -6 20 -27 6 -13 -8 -13 -10 -1
-10 11 0 9 -4 -5 -15 -26 -20 -34 -19 -29 5 2 11 -2 20 -8 20 -7 0 -4 5 5 10
9 6 15 19 13 30 -2 12 1 17 9 14 7 -3 13 2 13 11 0 10 -6 13 -18 10 -9 -3 -21
0 -25 7 -4 7 -4 10 0 6 10 -8 47 23 40 34 -3 4 2 5 10 2 12 -5 13 -11 5 -25
-7 -14 -6 -19 3 -19 9 0 9 -3 -2 -10 -13 -9 -13 -10 0 -10 13 0 13 -2 1 -9
-20 -13 -24 -44 -4 -37 8 3 17 6 19 6 2 0 4 27 4 60 0 57 -1 60 -25 60 -14 0
-26 -3 -26 -7z"/>
<path d="M2075 1050 c-25 -17 -54 -60 -39 -60 14 0 84 55 84 66 0 18 -8 17
-45 -6z"/>
<path d="M1085 1463 c-19 -5 -44 -30 -45 -45 0 -4 20 -16 44 -26 24 -11 47
-25 50 -31 11 -17 -5 -13 -42 9 -35 21 -52 25 -52 11 0 -8 100 -64 105 -59 2
2 5 17 9 33 6 28 3 31 -46 53 -28 12 -49 25 -45 28 3 3 28 -5 55 -19 50 -25
51 -25 68 -6 9 10 25 19 36 19 31 0 20 16 -21 28 -39 12 -80 14 -116 5z"/>
<path d="M1211 1391 c-11 -11 -22 -35 -25 -53 -9 -50 -12 -338 -4 -338 3 0 14
3 23 6 13 6 14 9 3 16 -9 6 -6 7 9 3 28 -8 86 42 115 99 44 86 30 232 -25 271
-30 21 -73 19 -96 -4z m103 -43 c5 -9 6 -21 2 -25 -5 -4 -11 -2 -13 5 -7 18
-20 14 -19 -6 0 -10 9 -26 19 -36 21 -21 22 -77 2 -115 -15 -30 -33 -41 -66
-41 -17 0 -19 8 -19 69 0 80 6 101 29 102 12 0 12 2 -4 9 -11 5 -14 9 -7 9 7
1 17 13 22 27 11 28 36 29 54 2z m-35 -264 c-20 -22 -25 -23 -27 -9 -4 19 13
35 37 35 10 0 7 -8 -10 -26z"/>
<path d="M1259 1238 c0 -13 -2 -31 -4 -40 -2 -10 1 -18 6 -18 16 0 27 35 18
58 -5 12 -11 22 -14 22 -3 0 -5 -10 -6 -22z"/>
<path d="M1040 1347 c1 -14 98 -62 107 -53 4 3 4 7 2 9 -53 31 -109 55 -109
44z"/>
<path d="M1037 1301 c2 -10 20 -25 39 -35 49 -23 57 -19 11 5 -22 11 -37 23
-34 26 3 3 24 -5 46 -18 23 -12 43 -20 46 -17 5 5 -90 58 -105 58 -4 0 -6 -8
-3 -19z"/>
<path d="M1039 1258 c-1 -7 0 -31 2 -53 1 -22 2 -57 2 -77 -1 -30 2 -36 15
-31 16 4 16 3 1 -9 -25 -21 -20 -31 34 -60 28 -16 51 -28 51 -28 1 0 2 8 3 18
1 13 -10 23 -34 31 -26 10 -32 16 -23 25 8 8 15 6 25 -8 8 -10 19 -15 26 -10
8 4 -4 15 -35 31 -39 20 -46 27 -42 48 3 14 0 25 -6 25 -6 0 -9 2 -6 5 3 3 13
2 22 -1 14 -5 15 -9 4 -15 -10 -7 -11 -9 0 -9 7 0 10 -5 6 -12 -4 -7 -3 -8 4
-4 14 8 45 -6 42 -20 -1 -5 4 -10 10 -12 9 -3 9 1 2 14 -5 11 -8 29 -6 42 3
15 -1 22 -12 22 -8 0 -12 5 -9 10 4 6 10 8 14 6 4 -3 8 11 9 30 1 29 -2 35
-14 30 -8 -3 -28 1 -44 9 -35 18 -40 18 -41 3z m61 -29 c32 -18 26 -19 -15 -3
-10 4 -15 2 -13 -6 2 -7 -2 -14 -8 -17 -6 -2 -11 3 -11 12 0 8 5 15 11 15 6 0
4 5 -4 10 -28 18 5 9 40 -11z m10 -29 c12 -8 12 -10 -4 -16 -13 -5 -14 -9 -5
-15 18 -11 20 -39 2 -32 -10 4 -11 8 -3 13 7 4 0 12 -19 20 -29 12 -30 14 -14
26 22 16 24 16 43 4z"/>
<path d="M1590 1461 c-25 -7 -25 -8 -3 -15 12 -4 34 -21 48 -38 l26 -31 35 19
c20 11 38 22 40 27 10 14 -28 19 -52 7 -13 -8 -27 -10 -30 -7 -4 4 6 11 22 17
55 21 -21 40 -86 21z"/>
<path d="M1513 1408 c-29 -35 -45 -226 -27 -317 9 -43 24 -39 24 7 0 21 5 43
12 50 9 9 8 12 -7 12 -19 1 -19 1 -2 11 21 13 59 3 39 -10 -9 -5 1 -13 28 -24
l40 -15 0 -56 c0 -44 4 -57 19 -66 11 -5 22 -10 25 -10 10 0 -2 284 -13 321
-20 66 -53 104 -92 107 -21 2 -40 -3 -46 -10z m55 -32 c-2 -8 6 -22 17 -31 19
-15 19 -15 -10 -9 -39 9 -39 9 -25 33 12 24 22 28 18 7z m26 -61 c15 -15 26
-61 24 -99 -1 -16 -7 -17 -45 -12 -59 8 -63 11 -63 53 0 61 48 94 84 58z"/>
<path d="M1703 1386 c-28 -18 -44 -46 -27 -46 14 0 83 46 81 54 -6 17 -21 15
-54 -8z"/>
<path d="M1718 1347 c-36 -21 -48 -37 -29 -37 16 0 81 41 81 51 0 13 -11 10
-52 -14z"/>
<path d="M1727 1313 c-20 -14 -36 -28 -34 -30 6 -5 87 41 87 50 0 13 -14 8
-53 -20z"/>
<path d="M1728 1287 c-39 -23 -51 -62 -15 -51 13 4 17 2 13 -5 -4 -6 -3 -11 3
-11 15 0 24 20 9 20 -15 1 20 30 36 30 5 0 1 -7 -10 -15 -20 -15 -8 -21 15 -6
10 6 10 13 2 28 -5 11 -7 23 -4 27 13 12 -13 3 -49 -17z"/>
<path d="M1753 1225 c-12 -8 -28 -12 -36 -9 -12 4 -14 -13 -15 -101 0 -77 3
-105 11 -102 7 2 12 9 12 14 0 6 4 15 9 20 5 5 6 2 1 -6 -5 -9 -3 -12 5 -9 17
5 22 39 7 37 -7 -1 -19 -1 -27 0 -13 1 -13 3 -1 10 18 11 55 -5 46 -20 -4 -5
-1 -9 4 -9 14 0 14 17 0 25 -6 5 -4 11 7 19 16 12 16 13 -1 20 -10 4 -23 2
-29 -4 -8 -8 -5 -9 9 -4 12 4 17 3 12 -2 -13 -14 -58 -13 -47 1 6 7 7 15 3 19
-4 4 1 4 12 0 11 -4 17 -4 13 1 -9 10 10 35 25 35 6 0 2 -7 -9 -16 -17 -14
-17 -15 -1 -10 11 3 16 2 12 -4 -3 -6 -2 -10 3 -10 5 0 9 15 9 34 l0 34 -34
-25 c-33 -26 -53 -26 -37 -1 5 7 9 17 9 22 0 5 11 15 25 24 l25 14 -25 -21
c-27 -23 -15 -29 15 -7 18 13 30 46 16 46 -3 0 -16 -7 -28 -15z"/>
<path d="M2415 1461 c-19 -7 -18 -8 5 -20 14 -7 34 -24 46 -38 21 -24 21 -24
57 -6 55 27 33 50 -28 28 -17 -6 -17 -4 3 12 13 10 20 22 16 26 -9 9 -73 8
-99 -2z"/>
<path d="M2333 1393 c-25 -38 -34 -333 -10 -333 11 0 16 14 19 50 1 28 -1 52
-7 54 -5 2 -3 7 6 10 8 4 35 -2 59 -12 l43 -18 1 -63 c1 -54 4 -66 24 -78 l22
-15 0 133 c-1 153 -10 209 -47 262 -21 30 -32 37 -59 37 -25 0 -38 -7 -51 -27z
m87 -53 c11 -7 11 -10 2 -10 -10 0 -10 -5 2 -22 15 -21 22 -98 11 -110 -3 -3
-25 0 -48 7 -38 11 -42 15 -45 48 -4 45 11 74 40 80 21 4 21 4 -2 6 -15 0 -32
-5 -40 -11 -12 -11 -12 -10 -1 5 15 19 57 22 81 7z"/>
<path d="M2533 1388 c-18 -11 -33 -26 -33 -34 0 -18 2 -18 44 7 20 12 36 27
36 35 0 18 -8 17 -47 -8z"/>
<path d="M2543 1347 c-21 -15 -31 -29 -30 -44 1 -13 5 -23 9 -23 14 0 78 42
78 52 0 5 -16 0 -36 -12 -19 -12 -37 -20 -40 -17 -3 3 13 16 35 29 40 23 53
39 29 37 -7 0 -27 -11 -45 -22z"/>
<path d="M2558 1287 c-22 -13 -36 -29 -35 -39 2 -22 6 -22 31 -3 14 11 16 15
5 15 -10 1 -9 5 5 15 27 20 45 19 20 -1 -19 -15 -19 -16 1 -10 17 5 17 4 -4
-12 l-23 -19 33 0 c19 0 29 2 24 4 -6 2 -10 19 -9 38 2 41 1 41 -48 12z"/>
<path d="M2534 1222 c-12 -7 -15 -42 -4 -42 3 0 15 7 26 16 18 14 18 15 -6 8
-24 -6 -24 -6 -5 10 20 15 10 23 -11 8z"/>
<path d="M2574 1218 c-5 -8 -2 -9 9 -5 27 10 19 0 -17 -22 -28 -18 -34 -27
-34 -57 0 -20 4 -34 11 -31 13 4 19 37 6 37 -21 0 -6 21 31 46 30 20 37 30 28
35 -18 12 -26 11 -34 -3z"/>
<path d="M2585 1165 c-16 -13 -25 -24 -19 -25 5 0 16 6 23 13 11 10 13 10 7 0
-4 -8 -2 -13 5 -13 7 0 4 -7 -7 -15 -19 -14 -9 -21 11 -7 8 5 18 72 11 72 0 0
-14 -11 -31 -25z"/>
<path d="M2544 1093 c-10 -7 -19 -28 -21 -48 -3 -36 2 -41 26 -26 19 12 24 44
6 37 -8 -3 -12 2 -11 12 2 9 9 18 17 20 11 3 12 2 1 -6 -11 -7 -11 -10 3 -16
9 -3 13 -10 10 -16 -10 -16 11 -12 25 5 7 8 9 15 6 15 -4 0 -2 8 3 18 9 16 6
19 -18 19 -16 0 -37 -6 -47 -14z"/>
<path d="M1210 881 c0 -17 -5 -33 -11 -36 -6 -4 -9 0 -7 11 5 30 -38 31 -46 2
-4 -13 -10 -18 -12 -11 -3 7 0 21 6 32 9 17 8 21 -6 21 -9 0 -23 -8 -30 -17
-13 -17 -13 -17 -14 0 0 25 -40 23 -40 -2 0 -11 -3 -26 -6 -35 -5 -14 12 -16
149 -16 139 0 156 2 161 18 3 9 4 4 2 -11 -3 -25 0 -28 21 -25 17 2 29 13 38
35 l14 32 -64 0 c-51 0 -65 -4 -68 -17 -3 -9 -3 -2 -1 16 1 17 -1 32 -7 32 -5
0 -9 -4 -9 -9 0 -23 -21 -62 -30 -56 -8 5 -7 11 1 21 9 11 8 14 -4 14 -10 0
-14 6 -11 15 4 9 0 15 -10 15 -11 0 -16 -9 -16 -29z"/>
<path d="M1460 886 c0 -13 -3 -31 -6 -40 -5 -12 3 -15 40 -14 41 0 46 3 46 23
0 13 3 30 6 39 3 9 2 16 -3 16 -6 0 -15 -15 -21 -32 -5 -18 -10 -25 -11 -15
-1 9 -5 17 -11 17 -5 0 -10 7 -10 15 0 8 -7 15 -15 15 -9 0 -15 -9 -15 -24z"/>
<path d="M1680 896 c0 -7 -9 -16 -20 -19 -12 -3 -20 -14 -20 -26 0 -20 5 -21
105 -21 l105 0 0 25 c0 21 -5 25 -29 25 -16 0 -31 6 -34 13 -3 8 -7 6 -11 -5
-18 -53 -19 -54 -23 -30 -5 25 -43 32 -43 7 0 -8 -4 -15 -10 -15 -6 0 -6 9 0
26 13 33 13 34 -5 34 -8 0 -15 -6 -15 -14z"/>
<path d="M1895 900 c-4 -6 -10 -24 -15 -40 -7 -26 -6 -30 10 -30 13 0 17 5 14
14 -3 8 0 17 5 21 6 3 9 11 6 16 -4 5 -1 9 4 9 6 0 11 5 11 10 0 13 -27 13
-35 0z"/>
<path d="M2033 870 c-8 -31 -7 -40 2 -40 11 0 25 41 25 71 0 24 -17 4 -27 -31z"/>
<path d="M2093 870 l-10 -40 64 0 c51 0 64 3 60 14 -3 7 0 19 6 25 8 8 6 11
-7 11 -12 0 -16 5 -12 15 3 9 0 15 -9 15 -9 0 -15 -9 -15 -22 0 -13 -5 -30
-11 -38 -8 -11 -9 -7 -6 18 5 42 -9 52 -18 12 -10 -44 -28 -50 -20 -6 10 49
-8 46 -22 -4z"/>
<path d="M2400 889 c0 -11 -5 -29 -11 -40 -10 -19 -8 -20 23 -17 25 2 34 8 36
26 2 14 -2 22 -12 22 -10 0 -13 6 -10 15 4 9 0 15 -10 15 -10 0 -16 -8 -16
-21z"/>
<path d="M1557 873 c-2 -5 -7 -20 -11 -35 -6 -25 -4 -28 19 -28 16 0 28 7 31
18 3 9 10 25 15 35 8 14 5 17 -20 17 -17 0 -32 -3 -34 -7z"/>
<path d="M1920 860 c-5 -10 -6 -20 -3 -24 11 -11 41 -7 46 7 4 10 6 10 6 0 1
-19 38 -16 45 3 11 29 6 32 -39 32 -33 -1 -49 -6 -55 -18z"/>
<path d="M2232 868 c-20 -20 -14 -38 12 -38 24 0 41 23 30 41 -7 13 -28 11
-42 -3z"/>
<path d="M2307 858 c-8 -29 -2 -36 28 -30 18 3 25 11 25 28 0 33 -45 34 -53 2z"/>
<path d="M2457 854 c-3 -16 0 -24 9 -24 8 0 14 7 14 15 0 8 5 15 10 15 6 0 10
-7 10 -15 0 -18 36 -20 43 -2 4 10 6 10 6 0 1 -19 38 -16 45 3 11 29 6 32 -63
32 -65 0 -69 -2 -74 -24z"/>
<path d="M2613 866 c3 -8 0 -19 -6 -25 -8 -8 4 -11 45 -11 42 0 57 4 62 16 10
25 0 30 -54 32 -39 1 -50 -2 -47 -12z"/>
<path d="M925 840 c-4 -6 12 -10 39 -10 25 0 46 4 46 8 0 12 -78 14 -85 2z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
